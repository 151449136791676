import React, { useEffect, useState } from 'react'
import { handleError } from 'API/error'
import { IFilterPaymentsPayload } from 'API/payment/constants'
import EmptyContentBox from 'components/EmptyContentBox'
import FormModel from 'components/FormModel'
import LoadingChakra from 'components/LoadingChakra'
import get from 'lodash/get'
import { observer } from 'mobx-react'
import { useLocation, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { IPaymentList } from 'types/payment'
import * as Yup from 'yup'
import { EConditionGetList, EStatusOfPaymentRequest } from 'constants/enum'
import { Messages } from 'constants/index'
import { isValidArray, getValidArray, getLastDayOfPreviousMonth } from 'utils/commonUtils'
import { useStores } from 'utils/hooks/useStores'
import FilterFormOfPayment from '../components/FilterFormPaymentOfAdmin'
import PaymentTable from '../components/PaymentTable'
import ShowRequestPaymentListModal from '../components/ShowRequestPaymentListModal'

const validationSchema = Yup.object().shape({
  date: Yup.string().required(Messages.fieldRequired),
})

interface IPaymentAdminProps {}

const PaymentAdmin = (props: IPaymentAdminProps) => {
  const { adminPaymentStore, adminProjectStore, adminUserStore } = useStores()
  const history = useHistory()
  const location = useLocation()
  const [isOpenRequestPaymentTable, setShowRequestPaymentTable] = useState(false)
  const [dataOfRequestPaymentTable, setDataOfRequestPaymentTable] = useState([])
  let query = useQuery()
  const projectId = query.get('project')
  const userId = query.get('user')
  const categoryId = query.get('category')
  const includedRequest = query.get('includedRequest')
  const { payments, paymentCategoryOptions, isPaymentLoading } = adminPaymentStore
  const { projectNameList } = adminProjectStore
  const { userNameList } = adminUserStore
  const nonEmptyPayments: IPaymentList[] = getValidArray(payments).filter((payment: IPaymentList) =>
    isValidArray(payment?.paymentData)
  )
  const emptyPayments: IPaymentList[] = getValidArray(payments).filter(
    (payment: IPaymentList) => !isValidArray(payment?.paymentData)
  )
  const date: string = getLastDayOfPreviousMonth()
  let filter = {}
  if (userNameList && userId) {
    const choosedUserData = userNameList.find((item) => item.id === userId)
    let filterUser = {
      value: userId,
      label: choosedUserData?.name ?? '',
    }
    Object.assign(filter, { user: filterUser })
  }
  function triggerShowRequestPaymentTable(data) {
    setShowRequestPaymentTable(!isOpenRequestPaymentTable)
    setDataOfRequestPaymentTable(data)
  }
  function closeShowRequestPaymentTableHandler() {
    setShowRequestPaymentTable(false)
  }
  if (projectNameList && projectId) {
    const choosedProjectData = projectNameList.find((item) => item.id === projectId)
    let filterProject = {
      value: projectId,
      label: choosedProjectData?.name ?? '',
    }
    Object.assign(filter, { project: filterProject })
  }
  if (paymentCategoryOptions && categoryId) {
    const choosedCategoryData = paymentCategoryOptions.find((item) => item._id === categoryId)
    let filterCategory = {
      value: projectId,
      label: choosedCategoryData?.value ?? '',
    }
    Object.assign(filter, { category: filterCategory })
  }
  const parseFilter = {
    ...filter,
    date,
    includedRequest,
  }
  useEffect(() => {
    adminUserStore.getAllNameUserList(EConditionGetList.ALL)
    adminProjectStore.getAllNameProjectList(EConditionGetList.ACTIVE)
    adminPaymentStore.getPaymentCategoryOptionsList()
  }, [])

  function useQuery() {
    const { search } = useLocation()
    return React.useMemo(() => new URLSearchParams(search), [search])
  }

  async function onSubmit(values): Promise<void> {
    const project = get(values, 'project.value', '')
    const user = get(values, 'user.value', '')
    const category = get(values, 'category.value', '')
    const date = get(values, 'date')
    const includedRequest = get(values, 'includedRequest')
    const queryString = {}
    if (project) {
      Object.assign(queryString, { project: project })
    }
    if (user) {
      Object.assign(queryString, { user: user })
    }
    if (category) {
      Object.assign(queryString, { category: category })
    }
    if (date) {
      Object.assign(queryString, { date: date })
    }
    if (includedRequest) {
      Object.assign(queryString, { includedRequest: includedRequest })
    }
    const parseFilterInFilterSubmit: IFilterPaymentsPayload = {
      ...values,
      project,
      user,
      category,
    }
    const params = new URLSearchParams(queryString)
    history.replace({ pathname: location.pathname, search: params.toString() })
    try {
      await adminPaymentStore.filterPayments(parseFilterInFilterSubmit)
    } catch (error) {
      handleError(error, 'src/containers/Payment/PaymentAdmin/index.tsx', 'onSubmit')
    }
  }

  async function handleEditRequestPayment(requestId: string, value: EStatusOfPaymentRequest): Promise<void> {
    try {
      await adminPaymentStore.editRequestPayment(requestId, value)
      closeShowRequestPaymentTableHandler()
      await onSubmit(parseFilter)
      toast.success('Edit request payment successfully')
    } catch (error) {
      toast.error('Edit request payment failed')
      handleError(error, 'src/containers/Payment/PaymentAdmin/index.tsx', 'handleEditRequestPayment')
    }
  }

  async function handleEditPayment(id: string, field: string, currentValue: boolean): Promise<void> {
    try {
      await adminPaymentStore.editPayment(id, field, currentValue)
      await onSubmit(parseFilter)
      toast.success('Edit payment successfully')
    } catch (error) {
      toast.error('Edit payment failed')
      handleError(error, 'src/containers/Payment/PaymentAdmin/index.tsx', 'handleEditPayment')
    }
  }

  return (
    <div className="timesheet-page container">
      <div>
        <div>
          <FormModel
            initialValues={parseFilter}
            schema={validationSchema}
            handleSubmit={onSubmit}
            component={
              <FilterFormOfPayment
                paymentCategories={paymentCategoryOptions}
                users={userNameList}
                projects={projectNameList}
                {...props}
              />
            }
          />
        </div>
        <LoadingChakra isLoading={isPaymentLoading}>
          {getValidArray(nonEmptyPayments).length === 0 && getValidArray(emptyPayments).length === 0 ? (
            <EmptyContentBox text={Messages.emptyBoxFilterMessage} position="bottom" />
          ) : (
            <div className="timesheet-content">
              <div className="container-fluid">
                {isValidArray(nonEmptyPayments) && (
                  <PaymentTable
                    paymentList={nonEmptyPayments}
                    editHandler={handleEditPayment}
                    isAdmin
                    triggerShowRequestPaymentTable={triggerShowRequestPaymentTable}
                  />
                )}
              </div>
            </div>
          )}
        </LoadingChakra>
      </div>
      <ShowRequestPaymentListModal
        isOpen={isOpenRequestPaymentTable}
        onClose={closeShowRequestPaymentTableHandler}
        data={dataOfRequestPaymentTable}
        editRequestPaymentHandler={handleEditRequestPayment}
      />
    </div>
  )
}

export default observer(PaymentAdmin)
