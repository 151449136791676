import capitalize from 'lodash/capitalize'

export const TitlePages = {
  ACTIVITY: 'Activity',
  LOGIN: 'Login',
  DASHBOARD: 'Dashboard',
  STRATEGY_DIAGRAM: 'Strategy Diagram',
  PARTNERS: 'Partners',
  PARTNER_DETAIL: 'Partner Detail',
  PARTNER_CREATE: 'Create Partner',
  USERS: 'Users',
  USER_DETAIL: 'User Detail',
  USER_CREATE: 'Create User',
  USER_CV: 'User CV',
  INTERNAL_DOCUMENT: 'Internal Documents',
  INTERNAL_DOCUMENT_NEW: 'Create Internal Document',
  INTERNAL_DOCUMENT_DETAIL: 'Internal Document Detail',
  INTERNAL_DOCUMENT_EDIT: 'Edit Internal Document',
  GLOBAL_CONFIG: 'Global Config',
  BILL_REPORT: 'Bill Report',
  REPORT: 'Report',
  TIMESHEET: 'Timesheet',
  TIMESHEET_OVERVIEW: 'Overview',
  HISTORY: 'History',
  PROJECTS: 'Projects',
  PROJECT_DETAIL: 'Project Detail',
  PROJECT_CREATE: 'Create Project',
  POLICY: 'Policy',
  POLICY_NEW: 'Create Policy',
  ANNOUNCEMENT: 'Announcement',
  ANNOUNCEMENT_NEW: 'Create Announcement',
  PAYMENTS: 'Payments',
  QR_CODE: 'QR Code',
  CONTRACTS: 'Contracts',
  CONTRACT_DETAIL: 'Contract Detail',
  BIRTHDAY: 'Birthday',
  PROFILE: 'Profile',
  GUIDELINE: 'Guideline',
}

const defaultPassword = '9999@UDT'

export const actionOnDataTable = {
  EDIT: 'EDIT',
  DELETE: 'DELETE',
  ARCHIVE: 'ARCHIVE',
  ACTIVE: 'ACTIVE',
  Summary: 'Summary',
  UNARCHIVED: 'UNARCHIVED',
}

export const Messages = {
  noData: 'There is no any data',
  fieldRequired: 'This field is required',
  fieldRequiredBy: (name) => `${capitalize(name)} is required`,
  projectFieldRequired: 'Please choose the project',
  isValidDateTimeOfTimesheet: 'Your Start time and End time are not valid',
  inValidStartTimeV2: "Start Time is not match format 'HH:mm'",
  invalidEmail: 'The email is invalid',
  serverError: 'There is something wrong',
  exceedCharacterOfString: 'You can not exceed 4000 characters',
  minOfCharacterTag: 'You can not add the tags lesser than 4 characters',
  requiredTagInThisProject: 'This project requires tags, please add tags',
  noticeEditTimesheet: '*If you want to edit or delete, please double click to the timesheet item',
  noticeEditTimesheetForSPScreen: '*If you want to edit or delete, please double click to the timesheet icon',
  noticeCreateTimesheet: '*Create new timesheet in here',
  validMaxMarginField: 'The margin can not be greater than 100%',
  validMinMarginField: 'The margin can not be lower than 1%',
  inValidRemuneration: 'The remuneration can not be greater than budget remain',
  sendRequestToEditPaymentSuccess: 'Your request has sent succesfully',
  sendRequestToEditPaymentFailed: 'Your request has sent failed',
  editRequestPaymentSuccess: 'Edit request payment successfully',
  editRequestPaymentFailed: 'Edit request payment failed',
  noteSendRequestForAllProjects:
    'If you request commit by ALL PROJECT, all commits with specific project will be overwrited.',
  deleteDialogTitle: 'Are you absolutely sure?',
  deleteDialogWarning: 'Unexpected bad things will happen if you don’t read this!',
  deleteDialogContent: 'This action cannot be undone. This will permanently delete the ',
  deleteDialogGuide: 'Please type in the name to confirm',
  deleteDialogYesNoContent: 'Do you want to delete this. Please confirm!',
  archiveDialogContent: 'This action cannot be undone. This will permanently archive the ',
  unarchiveDialogContent: 'This action cannot be undone. This will permanently unarchive the ',
  sendRequestDialogTitle: 'Do you want to send request to change the commit time?',
  sendRequestDialogContentPart1: 'Your current commit time is ',
  sendRequestDialogContentPart2:
    'If you want to send request to change the commit time, please enter the new commit time',
  sendRequestDialogWarning: 'Your request will be considered by admin.',
  editCommitTimeDialogTitle: 'Do you want to edit commit time?',
  editCommitTimeDialogWarning: 'Unexpected bad things will happen if you don’t read this!',
  editCommitTimeDialogContentPart1: 'The current commit time is',
  editCommitTimeDialogContentPart2: 'If you want to edit the commit time, please enter new value',
  rejectRequestDialogTitle: 'Do you want to reject this request?',
  rejectRequestDialogWarning: 'Unexpected bad things will happen if you don’t read this!',
  rejectRequestDialogContentPart1: 'The current commit time is ',
  rejectRequestDialogContentPart2: 'If you want to reject the request, please enter your reason',
  userCreatedWithDefaultPasswordAlert: 'New User has default password',
  uploadCVProfileSuccess: 'Upload CV profile successfully',
  createPartialTimesheetSuccess: 'Partial timesheet(s) created successfully! Please check overlaped logs again.',
  createAllSmartTimesheetFailed: 'Timesheet(s) created failed!',
  createAllSmartTimesheetSuccess: 'Timesheet(s) created successfully!',
  sendRequestChangeCommitTimeSuccessfully: 'Send request change commit time successfully',
  rejectRequestChangeCommitTimeSuccessfully: 'Reject request change commit time successfully',
  approveRequestChangeCommitTimeSuccessfully: 'Approve request change commit time successfully',
  noNewNotification: 'There are not new notifications',
  uploadCVSuccess: 'The CV has uploaded successfully',
  updateProfileSuccess: 'Update profile successfully',
  pdfOnlyAcceptanceMessage: 'Only .pdf files are accepted',
  notifyBrowser: {
    title: 'Avengers Timesheet',
    updateTimesheetBody: 'Someone updates timesheet',
  },
  notAllowToDownloadCV: 'You are not allow to download this CV',
  longPollingSuccess: 'Success',

  noticeCopyLastWeekWorkingSchedules:
    '*If you want to copy the working schedules of last week, click to edit then click to COPY ICON BUTTON',
  warningCopyLastWeekWorkingSchedules:
    'NOTE: When you press the copy button, all current schedules will be overwritten. Please carefully!',
  noTimeTotalChart: 'This project  has not time total chart',
  noProfitChart: 'This project has not profit chart',
  noRevenueChart: 'This project has not revenue chart',
  invalidNewCommitTimeValue: 'Your new commit value is invalid and it must be lesser than 168 hours',
  invalidNewCommitTimeReason: 'Your reason must be greater than 20 characters and lesser than 200 characters',
  closeCommitTimeRequestSuccessfully: 'Close commit time request successfully',
  bankNameNote:
    '<Short name - City - Branch - Full name> | Ex: vietcombank - HCM - Ky Dong - Ngân hàng thương mại cổ phần Ngoại thương Việt Nam',
  fullNameNote: 'Please enter the name on bank account ',
  displayNameNote: 'Please enter the display name ',
  updateUserAlertTitle: 'Update important indicators of new user',
  updateUserAlertDescription: `Please set Level, Title, Payment category of new user. And new user has default password is ${defaultPassword}_<user's email>`,
  externalProjectNote: 'They are projects not under the management of UDT Company',
  pressToExpandTimesheetDetailGuide: 'Click Task Name to view more information',
  pressToZoomCalendarGuide: 'Click Timeline headers to zoom in or zoom out date time',
  unauthorized: 'Unauthorized!',
  editProjectOfTimesheetNote: `The project for this timesheet won't be editable. But no worries! You can simply duplicate it, make your changes avoid overlap time, and then delete the original`,
  emptyBoxFilterMessage: `Please apply your filters to see the analytics.`,
  unarchivedProjectGuide: `Ready to bring this project back to life? Unarchiving it will make it active again and grant access to the users you select below. Click on a user's name for details.`,
  unarchivedUserGuide: `Ready to bring this user back to life? Unarchiving it will make it active again and grant access to the projects you select below. Click on a project's name for details.`,
  addMoreProjectToExportData: `Do you want to add a few more projects or select all projects to export data?`,
  noDoneTask: 'There is no DONE task',
  deleteInternalDocumentSuccess: 'Delete internal document successfully',
  updateInternalDocumentSuccess: 'Update internal document successfully',
  updateInternalDocumentFailed: 'Update internal document failed',
  createInternalDocumentSuccess: 'Create internal document successfully',
  createInternalDocumentFailed: 'Create internal document failed',
}

const apiUrlV2 = process.env.REACT_APP_API_URL_V2
const imageStorageURL = process.env.REACT_APP_IMAGES_STORAGE_URL
const timesheetImageURL = process.env.REACT_APP_TIMESHEET_IMAGES_STORAGE_URL
const fileStorageURL = process.env.REACT_APP_FILES_STORAGE_URL
const shareCVURL = process.env.REACT_APP_SHARE_CV_FILE_URL
const taskManagementURL = process.env.REACT_APP_TASK_MANAGEMENT_URL
const featureFlagURL = process.env.REACT_APP_FEATURE_FLAG_API_URL
const featureFlagToken = process.env.REACT_APP_FEATURE_FLAG_API_ACCESS_TOKEN
const environment = process.env.REACT_APP_ENVIRONMENT
const smartLookKey = process.env.REACT_APP_SMART_LOOK_KEY

export const AppConfig = {
  ENVIROMENT: environment,
  FEATURE_FLAG_API_ACCESS_TOKEN: featureFlagToken,
  FEATURE_FLAG_API_URL: featureFlagURL,
  IMAGES_STORAGE_URL: imageStorageURL,
  TIMESHEET_IMAGES_STORAGE_URL: timesheetImageURL,
  FILES_STORAGE_URL: fileStorageURL,
  SHARE_CV_URL: shareCVURL,
  API_URL_V2: apiUrlV2,
  SMART_LOOK_KEY: smartLookKey,
  DEFAULT_TOKEN: process.env.REACT_APP_DEFAULT_TOKEN,
  LIMIT_CHARACTERS_OF_STRING: 4000,
  MIN_CHARACTERS_OF_REQUEST_COMMIT_TIME_REASON: 20,
  MAX_CHARACTERS_OF_REQUEST_COMMIT_TIME_REASON: 200,
  MIN_CHARACTERS_OF_TAG: 4,
  MAX_TIME_SEND_REQUEST_COMMIT_TIME: 168,
  TASK_MANAGEMENT_URL: taskManagementURL,
  chart: {
    normal: {
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'middle',
    },
    responsive: {
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'bottom',
    },
  },
  TIME_OUT_LONGPOLLING: 60000,
  ENABLED_DOWNLOAD_CV: 'JXCExDUF6SBkurn2gTeQPtqy3zNfbp',
  DISALLOW_DOWNLOAD_CV: 'QxKFs9cAtRYDHXN32W4v8eVrByEuqZ',
}

export const EventName = {
  openErrorSnackbar: 'error-snack-bar',

  openLoading: 'open-loading',
  closeLoading: 'close-loading',

  openDeleteProjectConfirmDialog: 'open-project-delete-confirm-dialog',
  updateDeleteProjectConfirmDialog: 'update-project-delete-confirm-dialog',

  openDeleteProjectSettingsConfirmDialog: 'open-project-settings-delete-confirm-dialog',
  updateDeleteProjectSettingsConfirmDialog: 'update-project-settings-delete-confirm-dialog',

  openDeleteUserConfirmDialog: 'open-user-delete-confirm-dialog',
  updateDeleteUserConfirmDialog: 'update-user-delete-confirm-dialog',

  updateDeletePartnerConfirmDialog: 'update-partner-delete-confirm-dialog',
  openDeletePartnerConfirmDialog: 'open-partner-delete-confirm-dialog',

  updateDeleteInternalDocumentConfirmDialog: 'update-internal-document-delete-confirm-dialog',
  openDeleteInternalDocumentConfirmDialog: 'open-internal-document-delete-confirm-dialog',

  openDeleteTimesheetConfirmDialogYesNo: 'open-timesheet-delete-confirm-dialog-yes-no',

  openDeleteInfoConfirmDialogYesNo: 'open-info-delete-confirm-dialog-yes-no',

  openDeleteAdvancePaymentConfirmDialogYesNo: 'open-advance-payment-delete-confirm-dialog-yes-no',

  openDeletePolicyConfirmDialogYesNo: 'open-policy-delete-confirm-dialog-yes-no',

  openDeleteFeedbackConfirmDialogYesNo: 'open-feedback-delete-confirm-dialog-yes-no',

  openCloseCommitTimeRequestDialogYesNo: 'open-close-commit-time-request-dialog-yes-no',

  storage: 'storage',
  mousedown: 'mousedown',
}

export const Months = [
  {
    key: 1,
    value: 'Jan',
  },
  {
    key: 2,
    value: 'Feb',
  },
  {
    key: 3,
    value: 'Mar',
  },
  {
    key: 4,
    value: 'Apr',
  },
  {
    key: 5,
    value: 'May',
  },
  {
    key: 6,
    value: 'Jun',
  },
  {
    key: 7,
    value: 'Jul',
  },
  {
    key: 8,
    value: 'Aug',
  },
  {
    key: 9,
    value: 'Sep',
  },
  {
    key: 10,
    value: 'Oct',
  },
  {
    key: 11,
    value: 'Nov',
  },
  {
    key: 12,
    value: 'Dec',
  },
]

export const noticeForChangeOptionOfPayment =
  'If you want to change this field, please send request to admin and give us the reason for this changing.'

export const allValue = 'all'
export const limitItemPerPage = 10

export const encryptDataVariables = {
  doNeedToEncryptData: process.env.REACT_APP_DO_NEED_ENCRYPT_DATA,
  encryptSecretKey: process.env.REACT_APP_ENCRYPT_SECRET_KEY ?? '',
}
