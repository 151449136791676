import { TitlePages } from 'constants/index'
import { frontendRoutes } from 'constants/routes'
import AnnouncementAdmin from 'containers/Announcement/AnnouncementAdmin'
import AnnouncementUser from 'containers/Announcement/AnnouncementUser'
import PolicyAdmin from 'containers/Policy/PolicyAdmin'
import PolicyUser from 'containers/Policy/PolicyUser'
import GlobalConfig from 'containers/Settings/GlobalConfig'
import TimeSheetAdminBillReport from 'containers/TimeSheet/TimeSheetAdmin/BillReport'
import TimeSheetAdminHistory from 'containers/TimeSheet/TimeSheetAdmin/History'
import TimeSheetAdminReport from 'containers/TimeSheet/TimeSheetAdmin/Report/index'
import TimeSheetAdminOverview from 'containers/TimeSheet/TimeSheetAdmin/TimeSheetOverview'
import TimesheetBoard from 'containers/TimeSheet/TimeSheetBoard'
import TimesheetUserHistory from 'containers/TimeSheet/TimeSheetUser/History'
import TimesheetUserReport from 'containers/TimeSheet/TimeSheetUser/Report'
import TimesheetUserSupervise from 'containers/TimeSheet/TimeSheetUser/Supervisor'
import Birthday from '../BirthdayOfStaff'
import Contract from '../Contract'
import ContractDetail from '../Contract/Detail'
import DashboardAdminHeatmap from '../Dashboard/DashboardAdmin/Heatmap'
import DashboardAdminSkill from '../Dashboard/DashboardAdmin/Skill'
import DashboardAdminStatistic from '../Dashboard/DashboardAdmin/Statistic'
import DashboardUserHeatmap from '../Dashboard/DashboardUser/Heatmap'
import DashboardUser from '../Dashboard/DashboardUser/Personal'
import DashboardUserPersonal from '../Dashboard/DashboardUser/Personal'
import DashboardUserStatistic from '../Dashboard/DashboardUser/Statistic'
import Diagram from '../Diagram'
import InternalDocumentCreate from '../InternalDocument/InternalDocumentForm'
import InternalDocumentList from '../InternalDocument/InternalDocumentList'
import PartnerCreate from '../Partner/PartnerCreate'
import PartnerDetail from '../Partner/PartnerDetail'
import PartnerList from '../Partner/PartnerList'
import Payment from '../Payment/Payment'
import PaymentAdmin from '../Payment/PaymentAdmin'
import Profile from '../Profile/ProfileDetail'
import ProjectNewCreate from '../Projects/ProjectCreate'
import ProjectDetail from '../Projects/ProjectDetail'
import ProjectDetailAdmin from '../Projects/ProjectDetailAdmin'
import ProjectsList from '../Projects/ProjectListOfUser'
import ProjectsNewListAdmin from '../Projects/ProjectsListAdmin'
import QRCodePage from '../QRCode/index'
import UserNewCreate from '../User/UserCreate'
import CurriculumViateDetail from '../User/UserCV'
import UserNewDetail from '../User/UserDetail'
import UserNewList from '../User/UserList'
import { IRoutes } from './interfaces'

const Routes: IRoutes = {
  admin: [
    {
      key: 'adminDashboardHeatmap',
      title: TitlePages.DASHBOARD,
      path: frontendRoutes.adminDashboardPage.heatmap.value,
      exact: true,
      component: DashboardAdminHeatmap,
    },
    {
      key: 'adminDashboardStatistic',
      title: TitlePages.DASHBOARD,
      path: frontendRoutes.adminDashboardPage.statistic.value,
      exact: true,
      component: DashboardAdminStatistic,
    },
    {
      key: 'adminDashboardSkill',
      title: TitlePages.DASHBOARD,
      path: frontendRoutes.adminDashboardPage.skill.value,
      exact: true,
      component: DashboardAdminSkill,
    },
    {
      key: 'dashboardUserDetail',
      title: TitlePages.DASHBOARD,
      path: frontendRoutes.userDashboardPage.detail.id.value,
      exact: true,
      component: DashboardUser,
    },
    {
      key: 'diagram',
      title: TitlePages.STRATEGY_DIAGRAM,
      path: frontendRoutes.diagramPage.diagramAdmin.value,
      exact: true,
      component: Diagram,
    },
    {
      key: 'payments',
      title: TitlePages.PAYMENTS,
      path: frontendRoutes.paymentPage.paymentAdmin.value,
      exact: true,
      component: PaymentAdmin,
    },
    {
      key: 'birthday',
      title: TitlePages.BIRTHDAY,
      path: frontendRoutes.birthdayPage.value,
      exact: true,
      component: Birthday,
    },
    {
      key: 'contract',
      title: TitlePages.CONTRACTS,
      path: frontendRoutes.contractPage.list.value,
      exact: true,
      component: Contract,
    },
    {
      key: 'contractDetail',
      title: TitlePages.CONTRACT_DETAIL,
      path: frontendRoutes.contractPage.contractDetail.id.value,
      exact: true,
      component: ContractDetail,
    },
    {
      key: 'usersNewList',
      title: TitlePages.USERS,
      path: frontendRoutes.userPage.list.value,
      exact: true,
      component: UserNewList,
    },
    {
      key: 'usersNewCreate',
      title: TitlePages.USER_CREATE,
      path: frontendRoutes.userPage.create.value,
      exact: true,
      component: UserNewCreate,
    },
    {
      key: 'usersNewEdit',
      title: TitlePages.USER_DETAIL,
      path: frontendRoutes.userPage.detail.id.value,
      exact: true,
      component: UserNewDetail,
    },
    {
      key: 'usersCVDetail',
      title: TitlePages.USER_CV,
      path: frontendRoutes.userPage.detail.cv.id.value,
      exact: true,
      component: CurriculumViateDetail,
    },
    {
      key: 'profileNew',
      title: TitlePages.PROFILE,
      path: frontendRoutes.profilePage.value,
      exact: true,
      component: Profile,
    },
    {
      key: 'projectsNewCreate',
      title: TitlePages.PROJECT_CREATE,
      path: frontendRoutes.projectPage.projectAdmin.create.value,
      exact: true,
      component: ProjectNewCreate,
    },
    {
      key: 'projectsNewAdmin',
      title: TitlePages.PROJECTS,
      path: frontendRoutes.projectPage.projectAdmin.list.value,
      exact: true,
      component: ProjectsNewListAdmin,
    },
    {
      key: 'projectsNewDetail',
      title: TitlePages.PROJECT_DETAIL,
      path: frontendRoutes.projectPage.projectAdmin.detail.id.value,
      exact: true,
      component: ProjectDetailAdmin,
    },
    {
      key: 'timesheetBoard',
      title: TitlePages.TIMESHEET,
      path: frontendRoutes.timesheetPage.board.value,
      exact: true,
      component: TimesheetBoard,
    },
    {
      key: 'timesheetAdminOverview',
      title: TitlePages.TIMESHEET,
      path: frontendRoutes.timesheetPage.timesheetAdmin.overview.value,
      exact: true,
      component: TimeSheetAdminOverview,
    },
    {
      key: 'timesheetAdminReport',
      title: TitlePages.TIMESHEET,
      path: frontendRoutes.timesheetPage.timesheetAdmin.report.value,
      exact: true,
      component: TimeSheetAdminReport,
    },
    {
      key: 'timesheetAdminBillReport',
      title: TitlePages.TIMESHEET,
      path: frontendRoutes.timesheetPage.timesheetAdmin.billReport.value,
      exact: true,
      component: TimeSheetAdminBillReport,
    },
    {
      key: 'timesheetAdminHistory',
      title: TitlePages.TIMESHEET,
      path: frontendRoutes.timesheetPage.timesheetAdmin.history.value,
      exact: true,
      component: TimeSheetAdminHistory,
    },
    {
      key: 'policyAdmin',
      title: TitlePages.POLICY,
      path: frontendRoutes.policyPage.policyAdmin.value,
      exact: true,
      component: PolicyAdmin,
    },
    {
      key: 'announcementAdmin',
      title: TitlePages.ANNOUNCEMENT,
      path: frontendRoutes.announcementPage.announcementAdmin.value,
      exact: true,
      component: AnnouncementAdmin,
    },
    {
      key: 'globalConfig',
      title: TitlePages.GLOBAL_CONFIG,
      path: frontendRoutes.settingPage.globalConfig.value,
      exact: true,
      component: GlobalConfig,
    },
    {
      key: 'partner',
      title: TitlePages.PARTNERS,
      path: frontendRoutes.partnerPage.list.value,
      exact: true,
      component: PartnerList,
    },
    {
      key: 'partner',
      title: TitlePages.PARTNER_CREATE,
      path: frontendRoutes.partnerPage.create.value,
      exact: true,
      component: PartnerCreate,
    },
    {
      key: 'partner',
      title: TitlePages.PARTNER_DETAIL,
      path: frontendRoutes.partnerPage.detail.id.value,
      exact: true,
      component: PartnerDetail,
    },
    {
      key: 'internalDocument',
      title: TitlePages.INTERNAL_DOCUMENT,
      path: frontendRoutes.internalDocument.list.value,
      exact: true,
      component: InternalDocumentList,
    },
    {
      key: 'internalDocument',
      title: TitlePages.INTERNAL_DOCUMENT_NEW,
      path: frontendRoutes.internalDocument.create.value,
      exact: true,
      component: InternalDocumentCreate,
    },
    {
      key: 'internalDocument',
      title: TitlePages.INTERNAL_DOCUMENT_DETAIL,
      path: frontendRoutes.internalDocument.detail.id.value,
      exact: true,
      component: InternalDocumentCreate,
    },
    {
      key: 'qrcode',
      title: TitlePages.QR_CODE,
      path: frontendRoutes.qrCodePage.value,
      exact: true,
      component: QRCodePage,
    },
  ],
  user: [
    {
      key: 'payments',
      title: TitlePages.PAYMENTS,
      path: frontendRoutes.paymentPage.paymentUser.value,
      exact: true,
      component: Payment,
    },
    {
      key: 'timesheetBoard',
      title: TitlePages.TIMESHEET,
      path: frontendRoutes.timesheetPage.board.value,
      exact: true,
      component: TimesheetBoard,
    },
    {
      key: 'timesheetUserReport',
      title: TitlePages.TIMESHEET,
      path: frontendRoutes.timesheetPage.timesheetUser.report.value,
      exact: true,
      component: TimesheetUserReport,
    },
    {
      key: 'timesheetUserHistory',
      title: TitlePages.TIMESHEET,
      path: frontendRoutes.timesheetPage.timesheetUser.history.value,
      exact: true,
      component: TimesheetUserHistory,
    },
    {
      key: 'timesheetUserSupervise',
      title: TitlePages.TIMESHEET,
      path: frontendRoutes.timesheetPage.timesheetUser.supervise.value,
      exact: true,
      component: TimesheetUserSupervise,
    },
    {
      key: 'projectsNewDetail',
      title: TitlePages.PROJECT_DETAIL,
      path: frontendRoutes.projectPage.projectUser.detail.id.value,
      exact: true,
      component: ProjectDetail,
    },
    {
      key: 'projectsNewList',
      title: TitlePages.PROJECTS,
      path: frontendRoutes.projectPage.projectUser.list.value,
      exact: true,
      component: ProjectsList,
    },
    {
      key: 'dashboardUserPersonal',
      path: frontendRoutes.userDashboardPage.personal.value,
      title: TitlePages.DASHBOARD,
      exact: true,
      component: DashboardUserPersonal,
    },
    {
      key: 'dashboardUserStatistic',
      path: frontendRoutes.userDashboardPage.statistic.value,
      title: TitlePages.DASHBOARD,
      exact: true,
      component: DashboardUserStatistic,
    },
    {
      key: 'dashboardUserHeatmap',
      path: frontendRoutes.userDashboardPage.heatmap.value,
      title: TitlePages.DASHBOARD,
      exact: true,
      component: DashboardUserHeatmap,
    },
    {
      key: 'policy',
      title: TitlePages.POLICY,
      path: frontendRoutes.policyPage.policyUser.value,
      exact: true,
      component: PolicyUser,
    },
    {
      key: 'announcementUser',
      title: TitlePages.ANNOUNCEMENT,
      path: frontendRoutes.announcementPage.announcementUser.value,
      exact: true,
      component: AnnouncementUser,
    },
    {
      key: 'profileNew',
      title: TitlePages.PROFILE,
      path: frontendRoutes.profilePage.value,
      exact: true,
      component: Profile,
    },
  ],
}

export default Routes
