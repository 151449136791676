import { useState } from 'react'
import { HStack, VStack } from '@chakra-ui/layout'
import { useMediaQuery } from '@chakra-ui/react'
import Button from 'components/Button'
import { laptopSize } from 'constants/common'
import { EContractTabs } from './constants'
import ContractList from './ContractList'
import ContractCreate from './Create'

const Contract = () => {
  const [isCreateContract, setIsCreateContract] = useState<boolean>(false)
  const [isTablet] = useMediaQuery(laptopSize)

  function closeCreateContract(): void {
    setIsCreateContract(false)
  }

  return (
    <VStack
      spacing={{ base: 6, lg: 8 }}
      alignItems={'flex-start'}
      backgroundColor="white"
      borderRadius="8px"
      p={{ base: 6, lg: 8 }}>
      {!isCreateContract && (
        <HStack spacing={3} alignSelf="flex-end" flex={1}>
          <Button variant="solid" leftIcon="add-white.svg" onClick={() => setIsCreateContract(true)}>
            {isTablet ? String(EContractTabs.CONTRACT_CREATE).split(' ')[0] : String(EContractTabs.CONTRACT_CREATE)}
          </Button>
        </HStack>
      )}
      {isCreateContract && <ContractCreate closeCreateContract={closeCreateContract} />}
      {!isCreateContract && <ContractList />}
    </VStack>
  )
}
export default Contract
