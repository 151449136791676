import { FormControl, FormLabel, Stack, Radio, RadioGroup } from '@chakra-ui/react'
import { capitalize } from 'lodash'
import { observer } from 'mobx-react'
import { useFormContext, useWatch } from 'react-hook-form'
import { getValidArray } from 'utils/commonUtils'

export interface IGroupRadio {
  name: string
  label?: any
  isEdit?: boolean
  data?: string[]
  defaultValue?: string
}

const GroupRadio = (props: IGroupRadio) => {
  const { name, label, data, defaultValue } = props
  const { register, control } = useFormContext()
  const currentValue: string = useWatch({ control, name })

  return (
    <FormControl id={name} width="full" padding={{ sm: '10px', md: '10px', lg: '0' }}>
      <FormLabel fontWeight={'nomal'} mb={2} color="gray.700" paddingBottom="12px">
        {label}
      </FormLabel>
      <RadioGroup defaultValue={defaultValue} value={currentValue}>
        <Stack direction="row">
          {getValidArray(data).map((item, index) => (
            <Radio {...register(name)} key={index} value={item} colorScheme="blue" width="50%">
              {capitalize(item)}
            </Radio>
          ))}
        </Stack>
      </RadioGroup>
    </FormControl>
  )
}

export default observer(GroupRadio)
