import React from 'react'
import * as Yup from 'yup'
import { withStyles } from '@material-ui/core/styles'
import SelectField from 'components/SelectField'
import FormModel from 'components/FormModel'
import { createOptionsOfReactSelectFromDB } from 'utils/dropdownOptionUtils'
import { Messages } from 'constants/index'
import Button from 'components/Button'

const styles = (theme) => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
    fontSize: 16,
  },
})

const FilterFormDetail = (props) => {
  const { classes, dirty, projects, isSubmitting, handleSubmit, isReportLoading } = props
  const projectsOptions = createOptionsOfReactSelectFromDB(projects)
  return (
    <form onSubmit={handleSubmit}>
      <div className="container-fluid">
        <div className="box box-default">
          <div className="box-header">Generate Project History</div>
          <div className="box-divider" />
          <div className={`box-body`}>
            <div className="container-fluid">
              <div className="fields">
                <div className="field">
                  <SelectField
                    name="project"
                    label="Project*"
                    options={projectsOptions}
                    isReactSelect
                    data={projects}
                  />
                </div>
              </div>
              <div className="submit-option">
                <Button isLoading={isReportLoading} type="submit" disabled={!dirty && isSubmitting}>
                  Generate
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}
const initialValues = {
  project: '',
}
const validationSchema = Yup.object().shape({
  project: Yup.object()
    .shape({
      label: Yup.string(),
      value: Yup.string(),
    })
    .required(Messages.fieldRequired),
})
class FilterForm extends React.Component {
  render() {
    const { projects, filterSubmitHandler } = this.props
    return (
      <FormModel
        initialValues={initialValues}
        schema={validationSchema}
        handleSubmit={filterSubmitHandler}
        component={<FilterFormDetail projects={projects} {...this.props} />}
      />
    )
  }
}

export default withStyles(styles)(FilterForm)
