import { ReactNode } from 'react'
import { FormControl, FormLabel, Input, HStack } from '@chakra-ui/react'
import NoDataText from 'components/NoDataText'
import { useFormContext } from 'react-hook-form'
import { getValidArray } from 'utils/commonUtils'
import { getFormattedSalary } from 'utils/numberUtils'
import BadgeImage from './BadgeImage'
import InputLabelForm from './InputLabelForm'
import { TextArea } from './labelForm.styles'
import TagList from './TagList'
import styles from './index.module.scss'
export interface ITextAreaProps {
  isHighligh?: boolean
  isReadonly?: boolean
  disabled?: boolean
}
export interface ILabelFormItemProps {
  name: string
  label: string
  type?: string
  placeholder?: string
  isRequired?: boolean
  children?: ReactNode
  helperText?: string
  readonly?: boolean
  disabled?: boolean
  isHighligh?: boolean
  isTagFormat?: boolean
  isTagListFormat?: boolean
  isMulti?: boolean
  isWordWrap?: boolean
  isSalary?: boolean
  isBadgeImage?: boolean
}
const LabelForm = (props: ILabelFormItemProps) => {
  const {
    name,
    label,
    type,
    readonly,
    disabled,
    isHighligh,
    isTagFormat,
    isTagListFormat,
    isMulti,
    isSalary,
    isBadgeImage,
  } = props
  const { getValues, register } = useFormContext()
  const currentValues = getValues(name)
  let formatedValue = currentValues
  if (isSalary && !isMulti) {
    formatedValue = getFormattedSalary(currentValues)
  }
  const disabledProps = disabled
    ? {
        disabled: true,
        background: 'white',
        color: 'gray.700',
      }
    : {}
  const textareaProps: ITextAreaProps = {
    isHighligh,
    isReadonly: readonly,
    disabled,
  }
  return (
    <FormControl padding={{ lg: '0px', md: '10px', sm: '10px' }}>
      <HStack spacing="14px" maxHeight={6} marginBottom={2} position="relative">
        <FormLabel fontWeight={'normal'} color="gray.700" lineHeight={6} marginBottom={0} marginInlineEnd={0}>
          {label}
        </FormLabel>
      </HStack>
      {type === 'textarea' ? (
        currentValues ? (
          <TextArea {...textareaProps} {...register(name)} readOnly={readonly} />
        ) : (
          <NoDataText />
        )
      ) : (
        <>
          {isMulti &&
            getValidArray(currentValues).map((currentValue: string) => {
              if (!currentValue) {
                return <NoDataText />
              }

              return (
                <Input
                  className={isTagFormat ? styles.tagFormat : ''}
                  paddingLeft={'0px'}
                  readOnly={readonly}
                  {...disabledProps}
                  value={currentValue}
                  fontSize="16px"
                  color={isHighligh ? 'blue.500' : 'gray.700'}
                  fontWeight={'500'}
                  width={'90%'}
                  border={'0px'}
                  _hover={{
                    bg: 'white',
                  }}
                />
              )
            })}
          {!isMulti && !formatedValue && <NoDataText />}
          <InputLabelForm
            isMulti={isMulti}
            formatedValue={formatedValue}
            isTagListFormat={isTagListFormat}
            isTagFormat={isTagFormat}
            readonly={readonly}
            disabledProps={disabledProps}
            isHighligh={isHighligh}
            isBadgeImage={isBadgeImage}
          />
          {isTagListFormat && <TagList currentValues={currentValues} name={name} />}
          {isBadgeImage && <BadgeImage data={currentValues} />}
        </>
      )}
    </FormControl>
  )
}

export default LabelForm
