import React from 'react'
import moment from 'moment'
import * as Yup from 'yup'
import omit from 'lodash/omit'
import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import set from 'lodash/set'
import queryString from 'query-string'
import { handleError } from 'API/error'
import { getAllNameProjectListOfAdminAPI } from 'API/project'
import { getBillReportAdminListAPI } from 'API/report'
import { isValidArray } from 'utils/commonUtils'
import { formattedExportHeader, formattedExportData } from 'utils/formatDataUtils'
import { Messages } from 'constants/index'
import { EConditionGetList, ETimesheetSubTitle } from 'constants/enum'
import EmptyContentBox from 'components/EmptyContentBox';
import LoadingChakra from 'components/LoadingChakra'
import FormModel from 'components/FormModel'
import { ExportCSVFileHeaders, ExportCSVFileHeadersOfTotalSheet } from '../../../../constants/defaultOptionList'
import BillReportTable from '../../components/BillReportTable'
import ReportTable from '../../components/BillReportTable/index'
import FilterForm from '../../components/FilterForm/FilterFormOfBillReport'
import ExportData from '../../components/ExportData'
import { QueryType } from '../enum'
import { checkReportData, checkSummaryData } from './container'



const today = moment().format('YYYY-MM-DD')

const validationSchema = Yup.object().shape({
  project: Yup.object()
    .shape({
      label: Yup.string(),
      value: Yup.string(),
    })
    .required(Messages.fieldRequired),
  date: Yup.string(),
})
class BillReport extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      projects: [],
      billReportList: [],
      paymentCategory: undefined,
      isShowPriceInExportFile: false,
      exportDataList: [],
      dateOfReport: undefined,
      summaryReport: undefined,
      isBillReportLoading: false,
      initialValues: {
        project: '',
        period: 'month',
        date: today,
        viewHourlyMode: true,
        anonymous: false,
        showPrice: false,
        current: true,
      },
    }
  }
  componentDidMount() {
    this.handleDidMount()
    this.getListProjectsHandler()
  }

  getListProjectsHandler = async () => {
    try {
      const response = await getAllNameProjectListOfAdminAPI(EConditionGetList.ALL)
      const projects = response?.data?.projects
      this.setState({ projects })
    } catch (error) {
      handleError(error, 'src/containers/TimeSheet/TimeSheetAdmin/BillReport/index.js', 'getListProjectsHandler')
    }
  }

  handleDidMount = () => {
    const { history } = this.props
    window.sessionStorage.setItem('subPageTitle', ETimesheetSubTitle.BILL_REPORT)
    window.dispatchEvent(new Event('storage'))
    const query = history.location.search
    if (query) {
      const parsed = queryString.parse(query)
      if (parsed[QueryType.CONFIG]) {
        const config = JSON.parse(parsed[QueryType.CONFIG])
        if (config) {
          this.setState({ initialValues: config })
          this.filterSubmitHandler(config)
        }
      }
    }
  }

  addQuery = (query) => {
    const { history } = this.props
    const currentPathName = history.location.pathname
    const currentSearch = history.location.search
    const parsed = queryString.parse(currentSearch)
    parsed[QueryType.CONFIG] = query
    delete parsed.index
    const stringified = queryString.stringify(parsed)
    const newLocation = `${currentPathName}?index=3&${stringified}`
    history.replace(newLocation)
  }

  filterSubmitHandler = async (values) => {
    try {
      this.setState({ isBillReportLoading: true })
      const newValues = cloneDeep(values)
      if (newValues) {
        if (newValues?.current) {
          delete newValues?.date
          // TODO: May use later, this create bug when select project
          //this.addQuery(JSON.stringify(newValues))
          set(newValues, 'date', today)
          delete newValues?.current
        } else {
        // TODO: May use later, this create bug when select project
        //this.addQuery(JSON.stringify(newValues))
          delete newValues?.current
        }
        if (newValues?.project) {
          newValues.project = get(newValues, 'project.value')
        }
      }
      const response = await getBillReportAdminListAPI(newValues)
      this.setState({
        billReportList: response?.billReportList,
        paymentCategory: response?.paymentCategory,
        isShowPriceInExportFile: response?.showPrice,
        exportDataList: response?.exportDataList,
        dateOfReport: response?.dateOfReport,
        anonymous: response?.anonymous,
        summaryReport: response?.summaryReport
      })
    } catch (error) {
      handleError(error, 'src/containers/TimeSheet/TimeSheetAdmin/BillReport/index.js', 'filterSubmitHandler')
    } finally {
      this.setState({ isBillReportLoading: false })
    }
  }
  render() {
    const {
      projects,
      billReportList,
      paymentCategory,
      isShowPriceInExportFile,
      exportDataList,
      dateOfReport,
      anonymous,
      summaryReport,
    } = this.state
    let enabledExportData = false
    let title
    if (billReportList && billReportList.length) {
      enabledExportData = true
      if (paymentCategory === 'Fixed Price') {
        title = 'Fixed Price'
      } else {
        title = 'Hourly Price'
      }
    }

    const hasNoReportData = !checkReportData(summaryReport, billReportList)
    const hasNoSummaryData = !checkSummaryData(summaryReport)

    return (
      <div className="timesheet-page container">
        <div>
          <FormModel
            initialValues={this.state.initialValues}
            schema={validationSchema}
            handleSubmit={this.filterSubmitHandler}
            component={<FilterForm isBillReportLoading={this.state.isBillReportLoading} projects={projects} {...omit(this.props, 'classes')} />}
          />
        </div>
        <LoadingChakra isLoading={this.state.isBillReportLoading} ><>
          <div className="timesheet-content">
            {hasNoReportData ? <EmptyContentBox text={Messages.emptyBoxFilterMessage} position="bottom" />:
            <div className="container-fluid">
            <div className="box box-default">
                <div className="box-header bill-report">Summary</div>
                <div className="box-divider" />
                <div className={`box-body`}>
                  {hasNoSummaryData ? <EmptyContentBox text={Messages.noData} /> : <ReportTable data={summaryReport} paymentCategory={paymentCategory} />}
                </div>
              </div>
              <div className="box box-default">
                <div className="box-header bill-report">
                  Timesheet Detail
                  {enabledExportData && (
                    <ExportData
                      data={formattedExportData(exportDataList, isShowPriceInExportFile, anonymous)}
                      headers={formattedExportHeader(
                        paymentCategory,
                        ExportCSVFileHeaders,
                        isShowPriceInExportFile
                      )}
                      headersOfTotalSheet={ExportCSVFileHeadersOfTotalSheet}
                      paymentCategory={paymentCategory}
                      dateOfReport={dateOfReport}
                    />
                  )}
                  {title && <p className="title">{title}</p>}
                </div>
                <div className="box-divider" />
                <div className={`box-body`}>
                  {!isValidArray(billReportList) && <EmptyContentBox text={Messages.noData} />}
                  {isValidArray(billReportList) && <BillReportTable billReportList={billReportList} />}
                </div>
              </div>
            </div>
            }
          </div>
        </>
        </LoadingChakra>
      </div>
    )
  }
}

export default BillReport
