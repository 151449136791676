import { useEffect, useState } from 'react'
import { Box } from '@chakra-ui/layout'
import ConfirmModal from 'components/ConfirmModal'
import { EModalType } from 'components/ConfirmModal/enums'
import GeneralList from 'components/GeneralList'
import LoadingChakra from 'components/LoadingChakra'
import { debounce, set } from 'lodash'
import { observer } from 'mobx-react'
import { IPolicyFilter } from 'types/policy'
import { IPolicy } from 'constants/schema'
import { useStores } from 'utils/hooks/useStores'
import HeaderSection from '../components/HeaderSection'
import PolicyFilterModal from './components/PolicyFilterModal'
import { IPolicyFilterData } from './components/PolicyFilterModal/interface'
import { IPolicyFormData } from './components/PolicyForm/interface'
import PolicyModal from './components/PolicyModal'

const PolicyAdmin = () => {
  const { adminPolicyStore } = useStores()
  const { policyGroupsByMonth, isLoading } = adminPolicyStore
  const [filter, setFilter] = useState<IPolicyFilter>({})
  const [isCreatePolicyModalOpen, setIsCreatePolicyModalOpen] = useState<boolean>(false)
  const [isEditPolicyModalOpen, setIsEditPolicyModalOpen] = useState<boolean>(false)
  const [isDeletePolicyModalOpen, setIsDeletePolicyModalOpen] = useState<boolean>(false)
  const [isFilterPolicyModalOpen, setIsFilterPolicyModalOpen] = useState<boolean>(false)
  const [selectedPolicy, setSelectedPolicy] = useState<IPolicy>()

  function openCreatePolicyModal() {
    setIsCreatePolicyModalOpen(true)
  }

  function closeCreatePolicyModal() {
    setIsCreatePolicyModalOpen(false)
  }

  function openConfirmDeleteModal(policy: IPolicy) {
    set(policy, 'shortName', policy?.title)
    setSelectedPolicy(policy)
    setIsDeletePolicyModalOpen(true)
  }

  function closeDeletePolicyModal() {
    setIsDeletePolicyModalOpen(false)
  }

  function openEditPolicyModal(policy: IPolicy) {
    setSelectedPolicy(policy)
    setIsEditPolicyModalOpen(true)
  }

  function closeEditPolicyModal() {
    setIsEditPolicyModalOpen(false)
  }

  function openFilterPolicyModal() {
    setIsFilterPolicyModalOpen(true)
  }

  function closeFilterPolicyModal() {
    setIsFilterPolicyModalOpen(false)
  }

  async function handleDeletePolicy() {
    adminPolicyStore.isLoading = true
    await adminPolicyStore.deletePolicy(selectedPolicy?._id)
    setIsDeletePolicyModalOpen(false)
    await adminPolicyStore.getPolicyGroupsByMonth()
  }

  async function createPolicy(data: IPolicyFormData) {
    const formatedData = {
      title: data.title,
      content: data.content,
      publishedDate: data.publishedDate,
      published: data.status === 'published',
    }
    adminPolicyStore.isLoading = true
    await adminPolicyStore.createPolicy(formatedData)
    closeCreatePolicyModal()
    await adminPolicyStore.getPolicyGroupsByMonth()
  }

  async function updatePolicy(data: IPolicyFormData) {
    const formatedData = {
      title: data.title,
      content: data.content,
      publishedDate: data.publishedDate,
      published: data.status === 'published',
    }
    adminPolicyStore.isLoading = true
    await adminPolicyStore.updatePolicy(formatedData, selectedPolicy?._id)
    closeEditPolicyModal()
    await adminPolicyStore.getPolicyGroupsByMonth()
  }

  async function filterPolicy(data: IPolicyFilterData) {
    const filterData = {}
    const publishedYear = new Date(data?.publishedYear).getFullYear()

    if (data.status !== null) {
      set(filterData, 'published', data.status === 'published')
    }

    if (data.publishedMonth) {
      set(filterData, 'publishedDate', {
        start: new Date(publishedYear, Number(data.publishedMonth.value) - 1, 1),
        end: new Date(publishedYear, Number(data.publishedMonth.value), 1),
      })
    } else {
      set(filterData, 'publishedDate', {
        start: new Date(publishedYear, 0, 1),
        end: new Date(publishedYear, 12, 1),
      })
    }

    setFilter(filterData)
    closeFilterPolicyModal()
  }

  const changeName = debounce((event: { target: { value: string } }) => {
    setFilter({
      ...filter,
      title: { $regex: event?.target?.value ?? '', $options: 'i' },
    })
  }, 500)

  useEffect(() => {
    adminPolicyStore.getPolicyGroupsByMonth(filter)
  }, [filter])

  return (
    <Box background="white" padding="24px" borderRadius="6px">
      <HeaderSection
        openCreateModal={openCreatePolicyModal}
        openFilterModal={openFilterPolicyModal}
        changeName={changeName}
      />
      <Box paddingY="6">
        <LoadingChakra isLoading={isLoading}>
          <GeneralList
            isAdmin={true}
            dataGroupsByMonth={policyGroupsByMonth}
            openConfirmDeleteModal={openConfirmDeleteModal}
            openEditModal={openEditPolicyModal}
          />
        </LoadingChakra>
        <PolicyModal
          isLoading={isLoading}
          isOpen={isCreatePolicyModalOpen}
          closeHandler={closeCreatePolicyModal}
          saveHandler={createPolicy}
        />
        <PolicyModal
          isLoading={isLoading}
          isEdit={true}
          isOpen={isEditPolicyModalOpen}
          closeHandler={closeEditPolicyModal}
          saveHandler={updatePolicy}
          data={selectedPolicy}
        />
        <ConfirmModal
          isLoading={isLoading}
          data={selectedPolicy}
          OKClickHandler={handleDeletePolicy}
          isOpen={isDeletePolicyModalOpen}
          closeHandler={closeDeletePolicyModal}
          title={'Policy'}
          type={EModalType.DELETE}
          content="title"
        />
        <PolicyFilterModal
          isOpen={isFilterPolicyModalOpen}
          isLoading={isLoading}
          closeHandler={closeFilterPolicyModal}
          submitHandler={filterPolicy}
        />
      </Box>
    </Box>
  )
}
export default observer(PolicyAdmin)
