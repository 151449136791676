import { useEffect } from 'react'
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  HStack,
  VStack,
  FormControl,
  FormLabel,
} from '@chakra-ui/react'
import Button from 'components/Button'
import DatePicker from 'components/DatePicker'
import GroupRadio from 'components/GroupRadio'
import SingleSelect from 'components/SingleSelect'
import dayjs from 'dayjs'
import { useForm, FormProvider, Controller } from 'react-hook-form'
import { textBlue500 } from 'theme/globalStyles'
import { MonthOfYear } from 'constants/defaultOptionList'
import { generalStatus } from 'constants/general'
import { handleDatePickerChange } from 'utils/dateFormatUtils'
import { IPolicyFilterModalProps } from './interface'

const PolicyFilterModal = (props: IPolicyFilterModalProps) => {
  const { isOpen, closeHandler, submitHandler } = props
  const methods = useForm()
  const { handleSubmit, control, reset } = methods

  useEffect(() => {
    if (isOpen) {
      resetForm()
    }
  }, [isOpen])

  function resetForm() {
    reset({
      publishedYear: dayjs().toDate(),
    })
  }

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={closeHandler} size="sm">
      <DrawerOverlay />
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(submitHandler)}>
          <DrawerContent>
            <DrawerCloseButton color={'gray'} background="none" cursor="pointer" mt="2" />
            <DrawerHeader>Filter</DrawerHeader>
            <DrawerBody>
              <VStack gap={5}>
                <GroupRadio name="status" data={generalStatus} label="Status" />
                <FormControl>
                  <FormLabel fontWeight="400">Published Year</FormLabel>
                  <Controller
                    name="publishedYear"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        selectedDate={field.value ? dayjs(field.value).toDate() : undefined}
                        onChange={(date) => handleDatePickerChange(field, date)}
                        placement="top"
                        showYearPicker={true}
                      />
                    )}
                  />
                </FormControl>
                <SingleSelect name="publishedMonth" label="Published Month" optionsData={MonthOfYear} isClearable />
              </VStack>
            </DrawerBody>
            <DrawerFooter justifyContent="space-between">
              <Button customStyles={{ color: textBlue500 }} variant="ghost" onClick={resetForm}>
                Reset
              </Button>
              <HStack>
                <Button variant="outline" onClick={closeHandler}>
                  Cancel
                </Button>
                <Button variant="solid" type="submit">
                  Apply Filter
                </Button>
              </HStack>
            </DrawerFooter>
          </DrawerContent>
        </form>
      </FormProvider>
    </Drawer>
  )
}

export default PolicyFilterModal
