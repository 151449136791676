import React, { useEffect, useState } from 'react'
import { useDisclosure } from '@chakra-ui/hooks'
import { HStack, VStack } from '@chakra-ui/react'
import { handleError } from 'API/error'
import { getUserBelongToPartner } from 'API/partner'
import Button from 'components/Button'
import ViewContractModal from 'components/ViewContractModal'
import omit from 'lodash/omit'
import { observer } from 'mobx-react'
import { FormProvider, useForm, UseFormReturn, useWatch } from 'react-hook-form'
import { toast } from 'react-toastify'
import { IOption } from 'constants/common'
import { EConditionGetList, EContractLanguages, EContractTypes, ELaborContractTerm } from 'constants/enum'
import { IContract, IUser } from 'constants/schema'
import { useStores } from 'utils/hooks/useStores'
import CommonInformation from './components/CommonInformation'
import ENInformation from './components/ENInformation'
import LaborContractDetail from './components/LaborContractDetail'
import VNInformation from './components/VNInformation'
import { IContractForm } from './constants'
import { IContractCreateProps } from './interface'
import { generateFormData } from './utils'

const ContractCreate = (props: IContractCreateProps) => {
  const { closeCreateContract } = props
  const { adminContractStore, adminPartnerStore } = useStores()
  const { currentContractContent } = adminContractStore
  const { partnerNameList } = adminPartnerStore
  const methods: UseFormReturn = useForm()
  const { handleSubmit, reset, control } = methods
  const [language, setLanguage] = useState<EContractLanguages>(EContractLanguages.VIETNAMESE)
  const [isShowDetailLaborContract, setIsShowDetailLaborContract] = useState<boolean>(false)
  const [isLaborContractDefiniteTerm, setIsLaborContractDefiniteTerm] = useState<boolean>(false)
  const [contractFileName, setContractFileName] = useState<string>('')
  const [currentSelectedPartner, setCurrentSelectedPartner] = useState<IOption | undefined>(undefined)
  const contractType: IOption<EContractTypes> = useWatch({ control, name: 'type' }) ?? {}
  const laborContractTerm: IOption = useWatch({ control, name: 'laborContractTerm' }) ?? {}
  const selectedPartner: IOption | undefined = useWatch({ control, name: 'partnerId' })
  const currentFormData: IContract = useWatch({ control })
  const { isOpen: isViewContract, onOpen: onViewContract, onClose: onCloseContract } = useDisclosure()

  function handleChangeLanguage(chosenLanguage: EContractLanguages): void {
    setLanguage(chosenLanguage)
  }

  function resetForm(): void {
    reset()
  }

  function getPartnerListWithFilter(): void {
    try {
      adminPartnerStore.getAllNamePartnerList(EConditionGetList.ACTIVE)
    } catch (error) {
      handleError(error, 'src/containers/Contract/Create/index.tsx', 'getPartnerListWithFilter')
    }
  }

  function handleShowDetailLaborContract(): void {
    setIsShowDetailLaborContract(contractType?.value === EContractTypes.LABOR_CONTRACT)
  }

  function handleLaborContractDefiniteTerm(): void {
    setIsLaborContractDefiniteTerm(laborContractTerm?.value === ELaborContractTerm.DEFINITE_TERM)
  }

  function handleCloseContract(): void {
    onCloseContract()
    resetForm()
  }

  async function handleCreateContract(data: IContractForm): Promise<void> {
    try {
      const { genderVI, genderEN } = data
      const contract: IContract = await adminContractStore.createContract({
        ...omit(data, 'genderVI'),
        language,
        genderVI: genderVI?.value,
        genderEN: genderEN?.value,
      })
      setContractFileName(contract?.fileName)
      await adminContractStore.showContractFile(contract?._id, contract?.fileName)
      onViewContract()
      toast.success('Create contract successfully!')
    } catch (error) {
      toast.error('Create contract failed!')
      handleError(error, 'src/containers/Contract/Create/index.tsx', 'handleCreateContract')
    }
  }

  async function handleSelectPartner(selectedPartner: IOption): Promise<void> {
    try {
      const user: IUser = await getUserBelongToPartner(selectedPartner?.value)
      const formData: IContractForm = generateFormData(user, currentFormData)
      reset(formData)
    } catch (error) {
      toast.error('Get user failed!')
      handleError(error, 'src/containers/Contract/Create/index.tsx', 'handleSelectPartner')
    }
  }

  useEffect(getPartnerListWithFilter, [])

  useEffect(handleShowDetailLaborContract, [contractType])

  useEffect(handleLaborContractDefiniteTerm, [laborContractTerm])

  useEffect(() => {
    if (selectedPartner && currentSelectedPartner?.value !== selectedPartner?.value) {
      setCurrentSelectedPartner(selectedPartner)
      handleSelectPartner(selectedPartner)
    }
  }, [selectedPartner])

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit((data) => handleCreateContract(data))} className="full-form">
        <HStack margin={{ base: 6, lg: 6 }} height="full" background="white" justifyContent="space-between">
          <HStack>
            <Button
              variant={language === EContractLanguages.VIETNAMESE ? 'solid' : 'outline'}
              onClick={() => handleChangeLanguage(EContractLanguages.VIETNAMESE)}>
              Vietnamese
            </Button>
            <Button
              variant={language === EContractLanguages.BILINGUAL ? 'solid' : 'outline'}
              onClick={() => handleChangeLanguage(EContractLanguages.BILINGUAL)}>
              Bilingual
            </Button>
          </HStack>
          <HStack>
            <Button colorScheme="red" onClick={closeCreateContract} variant="outline">
              Cancel
            </Button>
            <Button colorScheme="green" onClick={resetForm} variant="outline">
              Reset
            </Button>
            <Button variant="solid" type="submit">
              Save
            </Button>
          </HStack>
        </HStack>
        <VStack p={2} paddingInline={{ base: 6, lg: 8 }} paddingStart={{ base: '27px' }} spacing="10px">
          <VStack
            background="white"
            spacing={{ base: 6, lg: 8 }}
            width="full"
            borderRadius="8px"
            alignItems={'initial'}>
            <CommonInformation
              partnerList={partnerNameList}
              isLaborContractDefiniteTerm={isLaborContractDefiniteTerm}
              contractType={contractType?.label}
            />
            <VNInformation />
            {isShowDetailLaborContract && <LaborContractDetail />}
            {language === EContractLanguages.BILINGUAL && (
              <React.Fragment>
                <ENInformation />
              </React.Fragment>
            )}
          </VStack>
        </VStack>
        <ViewContractModal
          isOpen={isViewContract}
          onClose={handleCloseContract}
          fileName={contractFileName}
          content={currentContractContent}
        />
      </form>
    </FormProvider>
  )
}

export default observer(ContractCreate)
