import React, { useState, useEffect } from 'react'
import { VStack, HStack } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import EmptyContentBox from 'components/EmptyContentBox'
import LoadingChakra from 'components/LoadingChakra'
import dayjs from 'dayjs'
import { observer } from 'mobx-react'
import { FormProvider, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { EConditionGetList, ETimesheetSubTitle } from 'constants/enum'
import { Messages } from 'constants/index'
import { getValidArray } from 'utils/commonUtils'
import { useStores } from 'utils/hooks/useStores'
import OverviewCalendar from '../components/OverviewCalendar'
import OverviewFilterForm from '../components/OverviewFitlerForm'
import OverviewHistory from '../components/OverviewHistory'
import OverviewReport from '../components/OverviewReport'
import TaskTypeAnalytics from '../components/TaskTypeAnalytics'
import { IOverviewFilterFormData, workingPeriodOptions } from '../constants'

const TimesheetOverview = () => {
  window.sessionStorage.setItem('subPageTitle', ETimesheetSubTitle.OVERVIEW)
  window.dispatchEvent(new Event('storage'))
  const { adminProjectStore, adminUserStore, adminReportStore } = useStores()
  const { projectNameList } = adminProjectStore
  const { userNameList } = adminUserStore
  const { timesheetList, haveData, choosedDate } = adminReportStore
  const [isValidAnalysis, setIsValidAnalysis] = useState(false)
  const [expandedRows, setExpandedRows] = useState([])
  function getValidationSchema() {
    const schema = yup.object({
      selectedProject: yup.object().required(Messages.fieldRequired),
      selectedUser: yup.array().required(Messages.fieldRequired),
    })
    return schema
  }

  const methods = useForm<IOverviewFilterFormData>({
    resolver: yupResolver(getValidationSchema()),
    defaultValues: {
      period: workingPeriodOptions[1],
      date: !Boolean(JSON.parse(localStorage.getItem('isPickPastMonth')))
        ? dayjs().toDate()
        : dayjs().subtract(1, 'month').endOf('month').toDate(),
    },
  })

  const { getValues } = methods
  const { selectedProject } = getValues()

  function selectScrollElement(elementId: string) {
    if (elementId) {
      const element = document.getElementById(elementId)
      if (element) {
        element.scrollIntoView({ block: 'center', behavior: 'smooth' })
      }
    }
  }

  function getExpandedRows(timesheetId: string) {
    const getArrayOfSelectedItems = getValidArray(timesheetList).map((timesheet) => {
      if (timesheet.id === timesheetId) {
        return true
      }
      return false
    })
    return getArrayOfSelectedItems
  }

  useEffect(() => {
    if (haveData && selectedProject) {
      setIsValidAnalysis(true)
    } else {
      setIsValidAnalysis(false)
    }
  }, [haveData])

  function viewAnalyticHandler(data: IOverviewFilterFormData) {
    const { selectedProject, selectedUser, period, date } = data
    const newUserNameList = getValidArray(selectedUser).filter((item) => {
      return item.value !== 'all'
    })
    const usersIdData = newUserNameList.map((item) => item.value)
    const requestData = {
      projectId: selectedProject.value,
      usersId: usersIdData,
      period: period.value,
      date: dayjs(date).format('YYYY-MM-DD'),
    }
    adminReportStore.getOverviewAnalytics(requestData)
  }

  useEffect(() => {
    adminProjectStore.getAllNameProjectList(EConditionGetList.ALL)
    adminUserStore.getAllNameUserList(EConditionGetList.ACTIVE)
  }, [])

  function getSelectedItemTimesheetFromCalendar(timesheetId: string) {
    const newExpandedRows = getExpandedRows(timesheetId)
    setExpandedRows(newExpandedRows)
    selectScrollElement(timesheetId)
  }

  return (
    <VStack width="full">
      <FormProvider {...methods}>
        <OverviewFilterForm
          projectNameList={projectNameList}
          userNameList={userNameList}
          viewAnalyticHandler={viewAnalyticHandler}
          isAnalysisLoading={adminReportStore.isViewAnalyticsLoading}
        />
        <LoadingChakra isLoading={adminReportStore.isViewAnalyticsLoading}>
          <>
            {isValidAnalysis && (
              <>
                <OverviewCalendar
                  selectItemTimesheetHandler={getSelectedItemTimesheetFromCalendar}
                  choosedDate={choosedDate}
                />
                <OverviewReport expandedRows={expandedRows} />
                <TaskTypeAnalytics />
                <OverviewHistory />
              </>
            )}
            {!isValidAnalysis && (
              <HStack width="inherit" background="white" margin={{ base: 2, lg: '16px' }}>
                <HStack width="inherit" padding={'16px'} minHeight={'80vh'}>
                  <EmptyContentBox text={Messages.emptyBoxFilterMessage} position="bottom" />
                </HStack>
              </HStack>
            )}
          </>
        </LoadingChakra>
      </FormProvider>
    </VStack>
  )
}

export default observer(TimesheetOverview)
