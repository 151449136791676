import React, { useState, useEffect } from 'react'
import { VStack, HStack } from '@chakra-ui/react'
import EmptyContentBox from 'components/EmptyContentBox'
import LoadingChakra from 'components/LoadingChakra'
import dayjs from 'dayjs'
import { observer } from 'mobx-react'
import { FormProvider, useForm } from 'react-hook-form'
import { EConditionGetList, ETimesheetSubTitle } from 'constants/enum'
import { Messages } from 'constants/index'
import { allValue } from 'constants/index'
import { EFilterType } from 'containers/Dashboard/enum'
import { getValidArray } from 'utils/commonUtils'
import { useStores } from 'utils/hooks/useStores'
import ReportFilterForm from '../components/ReportFilterForm'
import ReportSection from '../components/ReportSection'
import { IReportFilterWithUserFormData, IReportFilterWithProjectFormData, workingPeriodOptions } from '../constants'

const AdminTimesheetReport = () => {
  window.sessionStorage.setItem('subPageTitle', ETimesheetSubTitle.REPORT)
  window.dispatchEvent(new Event('storage'))
  const { adminProjectStore, adminUserStore, adminReportStore } = useStores()
  const { projectNameList } = adminProjectStore
  const { userNameList } = adminUserStore
  const { isReportLoading, haveData, reportListData } = adminReportStore
  const [isValidAnalysis, setIsValidAnalysis] = useState(false)

  useEffect(() => {
    if (haveData) {
      setIsValidAnalysis(true)
    } else {
      setIsValidAnalysis(false)
    }
  }, [haveData])

  function reportWithProjectHandler(data: IReportFilterWithProjectFormData): void {
    const { selectedProject, selectedUser, period, date, isFilterByProject } = data
    const projectsId = [selectedProject?.value]
    const newUserNameList = getValidArray(selectedUser).filter((item) => {
      return item.value !== allValue
    })
    const usersId = newUserNameList.map((item) => item.value)
    const requestData = {
      projectsId,
      usersId,
      period: period.value,
      date: dayjs(date).format('YYYY-MM-DD'),
      filterType: isFilterByProject ? EFilterType.FILTER_BY_PROJECT : EFilterType.FILTER_BY_USER,
    }
    adminReportStore.getReportAnalytics(requestData)
  }

  function reportWithUserHandler(data: IReportFilterWithUserFormData): void {
    const { selectedProject, selectedUser, period, date, isFilterByProject } = data
    const usersId = [selectedUser?.value]
    const newProjectNameList = getValidArray(selectedProject).filter((item) => {
      return item.value !== allValue
    })
    const projectsId = newProjectNameList.map((item) => item.value)
    const requestData = {
      projectsId,
      usersId,
      period: period.value,
      date: dayjs(date).format('YYYY-MM-DD'),
      filterType: isFilterByProject ? EFilterType.FILTER_BY_PROJECT : EFilterType.FILTER_BY_USER,
    }
    adminReportStore.getReportAnalytics(requestData)
  }

  useEffect(() => {
    adminProjectStore.getAllNameProjectList(EConditionGetList.ALL)
    adminUserStore.getAllNameUserList(EConditionGetList.ALL)
  }, [])

  const methods = useForm({
    defaultValues: {
      isFilterByProject: true,
      period: workingPeriodOptions[1],
      date: !Boolean(JSON.parse(localStorage.getItem('isPickPastMonth')))
        ? dayjs().toDate()
        : dayjs().subtract(1, 'month').endOf('month').toDate(),
    },
  })

  const { getValues } = methods
  const { isFilterByProject } = getValues()

  return (
    <VStack width="full">
      <FormProvider {...methods}>
        <ReportFilterForm
          projectNameList={projectNameList}
          userNameList={userNameList}
          reportWithProjectHandler={reportWithProjectHandler}
          reportWithUserHandler={reportWithUserHandler}
          isAnalysisLoading={isReportLoading}
        />
        <LoadingChakra isLoading={isReportLoading}>
          <>
            {isValidAnalysis &&
              getValidArray(reportListData).map((reportData) => {
                return <ReportSection reportData={reportData} isFilterByProject={isFilterByProject} />
              })}
            {!isValidAnalysis && (
              <HStack width="inherit" background="white" margin={{ base: 2, lg: '16px' }}>
                <HStack width="inherit" padding={'16px'} minHeight={'80vh'}>
                  <EmptyContentBox text={Messages.emptyBoxFilterMessage} position="bottom" />
                </HStack>
              </HStack>
            )}
          </>
        </LoadingChakra>
      </FormProvider>
    </VStack>
  )
}

export default observer(AdminTimesheetReport)
