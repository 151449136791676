import { handleError } from 'API/error'
import { getAllNameProjectListOfAdminAPI } from 'API/project'
import { getProjectHistoryAPI, getUserHistoryAPI } from 'API/timesheet'
import { getAllNameUserListAPI } from 'API/user'
import RadioButton from 'components/RadioButton/RadioButtonWithoutFormik'
import get from 'lodash/get'
import React from 'react'
import ProjectFilterForm from './Projects/FilterForm'
import ProjectTable from './Projects/Table'
import UserFilterForm from './Users/FilterForm'
import UserTable from './Users/Table'
import { EConditionGetList, ETimesheetSubTitle } from 'constants/enum'
import EmptyContentBox from 'components/EmptyContentBox'
import { Messages } from 'constants/index'
import LoadingChakra from 'components/LoadingChakra'

class History extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedMode: 'project',
      projects: [],
      users: [],
      isReportLoading: false,
      projectHistoryData: undefined,
      userHistoryData: [],
    }
  }
  componentDidMount() {
    window.sessionStorage.setItem('subPageTitle', ETimesheetSubTitle.HISTORY)
    window.dispatchEvent(new Event('storage'))
    this.getListProjectsHandler()
    this.getListUsersHandler()
  }

  getListProjectsHandler = async () => {
    try {
      const response = await getAllNameProjectListOfAdminAPI(EConditionGetList.ACTIVE)
      this.setState({
        projects: response?.data?.projects,
      })
    } catch (error) {
      handleError(error, 'src/containers/TimeSheet/TimeSheetAdmin/History/index.js', 'getListProjectsHandler')
    }
  }

  getListUsersHandler = async () => {
    try {
      const response = await getAllNameUserListAPI(EConditionGetList.ACTIVE)
      this.setState({
        users: response?.data?.users,
      })
    } catch (error) {
      handleError(error, 'src/containers/TimeSheet/TimeSheetAdmin/History/index.js', 'getListUsersHandler')
    }
  }

  switchMode = () => {
    const { selectedMode } = this.state
    let nextMode
    if (selectedMode === 'user') {
      nextMode = 'project'
    } else {
      nextMode = 'user'
    }
    this.setState({
      selectedMode: nextMode,
    })
  }
  renderOptionFilter = () => {
    const { selectedMode, projects, users } = this.state
    if (selectedMode === 'project') {
      return <ProjectFilterForm isReportLoading={this.state.isReportLoading} projects={projects} filterSubmitHandler={this.generateProjectHistory} />
    } else {
      return <UserFilterForm isReportLoading={this.state.isReportLoading} users={users} filterSubmitHandler={this.generateUserHistory} />
    }
  }

  generateProjectHistory = async (values) => {
    try {
      this.setState({ isReportLoading: true })
      const parsedValues = {
        ...values,
      }
      if (values?.project) {
        parsedValues.project = get(values, 'project.value')
      }
      const response = await getProjectHistoryAPI(parsedValues)
      this.setState({
        projectHistoryData: response?.data?.projectHistoryData,
      })
    } catch (error) {
      handleError(
        error,
        'src/containers/TimeSheet/TimeSheetAdmin/History/index.js',
        'generateProjectHistory',
        false,
        true
      )
    } finally {
      this.setState({ isReportLoading: false })
    }
  }

  generateUserHistory = async (values) => {
    try {
      this.setState({ isReportLoading: true })
      const parsedValues = {
        ...values,
      }
      if (values?.user) {
        parsedValues.user = get(values, 'user.value')
      }
      const response = await getUserHistoryAPI(parsedValues)
      this.setState({
        userHistoryData: response?.data?.userHistoryData,
      })
    } catch (error) {
      handleError(error, 'src/containers/TimeSheet/TimeSheetAdmin/History/index.js', 'generateUserHistory')
    } finally {
      this.setState({ isReportLoading: false })
    }
  }

  renderBody = () => {
    const { selectedMode, projectHistoryData, userHistoryData } = this.state
    let content
    if (selectedMode === 'project') {
      content = <ProjectTable data={projectHistoryData} />
    } else {
      content = <UserTable data={userHistoryData} />
    }

    return (
      <div className="timesheet-content">
        <div className="container-fluid">
          <div className="box box-default">
            <div className="box-header">Summary</div>
            <div className="box-divider" />
            <div className={`box-body`}>{content}</div>
          </div>
        </div>
      </div>
    )
  }
  render() {
    const { selectedMode } = this.state
    let isModeUser = false
    if (selectedMode === 'user') {
      isModeUser = true
    }
    return (
      <div className="timesheet-page">
          <div className="options-group">
            <RadioButton label="Choose Mode User" value={isModeUser} clickHandler={this.switchMode} />
            {this.renderOptionFilter()}
          </div>
          <LoadingChakra isLoading={this.state.isReportLoading}>
            <>
            <div className="body">
              {!this.state.projectHistoryData && !this.state.userHistoryData?.length ? (
                <EmptyContentBox text={Messages.emptyBoxFilterMessage} position="bottom" />
              ) : (
                this.renderBody()
              )}
            </div>
          </>
          </LoadingChakra>
      </div>
    )
  }
}

export default History
