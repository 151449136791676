import { useEffect, useState } from 'react'
import { Box } from '@chakra-ui/layout'
import ConfirmModal from 'components/ConfirmModal'
import { EModalType } from 'components/ConfirmModal/enums'
import GeneralList from 'components/GeneralList'
import LoadingChakra from 'components/LoadingChakra'
import { debounce, set } from 'lodash'
import { observer } from 'mobx-react'
import { IAnnouncementFilter } from 'types/announcement'
import { IAnnouncement } from 'constants/schema'
import { useStores } from 'utils/hooks/useStores'
import HeaderSection from '../components/HeaderSection'
import { IAnnouncementFormData } from './components/AnnouncementForm/interface'
import AnnouncementModal from './components/AnnouncementModal'
import FilterModal from './components/FilterModal'
import { IFilterData } from './components/FilterModal/interface'

const AnnouncementAdmin = () => {
  const { adminAnnouncementStore } = useStores()
  const { announcementGroupsByMonth, isLoading } = adminAnnouncementStore
  const [filter, setFilter] = useState<IAnnouncementFilter>({})
  const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false)
  const [isFilterModalOpen, setIsFilterModalOpen] = useState<boolean>(false)
  const [selectedAnnouncement, setSelectedAnnouncement] = useState<IAnnouncement>()

  function openCreateModal() {
    setIsCreateModalOpen(true)
  }

  function closeCreateModal() {
    setIsCreateModalOpen(false)
  }

  function openConfirmDeleteModal(announcement: IAnnouncement) {
    set(announcement, 'shortName', announcement?.title)
    setSelectedAnnouncement(announcement)
    setIsDeleteModalOpen(true)
  }

  function closeDeleteModal() {
    setIsDeleteModalOpen(false)
  }

  function openEditModal(announcement: IAnnouncement) {
    setSelectedAnnouncement(announcement)
    setIsEditModalOpen(true)
  }

  function closeEditModal() {
    setIsEditModalOpen(false)
  }

  function openFilterModal() {
    setIsFilterModalOpen(true)
  }

  function closeFilterModal() {
    setIsFilterModalOpen(false)
  }

  async function handleDeleteAnnouncement() {
    adminAnnouncementStore.isLoading = true
    await adminAnnouncementStore.deleteAnnouncement(selectedAnnouncement?._id)
    setIsDeleteModalOpen(false)
    await adminAnnouncementStore.getAnnouncementGroupsByMonth()
  }

  async function createAnnouncement(data: IAnnouncementFormData) {
    const formatedData = {
      title: data.title,
      content: data.content,
      publishedDate: data.publishedDate,
      published: data.status === 'published',
    }
    adminAnnouncementStore.isLoading = true
    await adminAnnouncementStore.createAnnouncement(formatedData)
    closeCreateModal()
    await adminAnnouncementStore.getAnnouncementGroupsByMonth()
  }

  async function updateAnnouncement(data: IAnnouncementFormData) {
    const formatedData = {
      title: data.title,
      content: data.content,
      publishedDate: data.publishedDate,
      published: data.status === 'published',
    }
    adminAnnouncementStore.isLoading = true
    await adminAnnouncementStore.updateAnnouncement(formatedData, selectedAnnouncement?._id)
    closeEditModal()
    await adminAnnouncementStore.getAnnouncementGroupsByMonth()
  }

  async function filterAnnouncement(data: IFilterData) {
    const filterData = {}
    const publishedYear = new Date(data?.publishedYear).getFullYear()

    if (data.status !== null) {
      set(filterData, 'published', data.status === 'published')
    }

    if (data.publishedMonth) {
      set(filterData, 'publishedDate', {
        start: new Date(publishedYear, Number(data.publishedMonth.value) - 1, 1),
        end: new Date(publishedYear, Number(data.publishedMonth.value), 1),
      })
    } else {
      set(filterData, 'publishedDate', {
        start: new Date(publishedYear, 0, 1),
        end: new Date(publishedYear, 12, 1),
      })
    }

    setFilter(filterData)
    closeFilterModal()
  }

  const changeName = debounce((event: { target: { value: string } }) => {
    setFilter({
      ...filter,
      title: { $regex: event?.target?.value ?? '', $options: 'i' },
    })
  }, 500)

  useEffect(() => {
    adminAnnouncementStore.getAnnouncementGroupsByMonth(filter)
  }, [filter])

  return (
    <Box background="white" padding="24px" borderRadius="6px">
      <HeaderSection openCreateModal={openCreateModal} openFilterModal={openFilterModal} changeName={changeName} />
      <Box paddingY="6">
        <LoadingChakra isLoading={isLoading}>
          <GeneralList
            isAdmin={true}
            dataGroupsByMonth={announcementGroupsByMonth}
            openConfirmDeleteModal={openConfirmDeleteModal}
            openEditModal={openEditModal}
          />
        </LoadingChakra>
        <AnnouncementModal
          isLoading={isLoading}
          isOpen={isCreateModalOpen}
          closeHandler={closeCreateModal}
          saveHandler={createAnnouncement}
        />
        <AnnouncementModal
          isLoading={isLoading}
          isEdit={true}
          isOpen={isEditModalOpen}
          closeHandler={closeEditModal}
          saveHandler={updateAnnouncement}
          data={selectedAnnouncement}
        />
        <ConfirmModal
          isLoading={isLoading}
          data={selectedAnnouncement}
          OKClickHandler={handleDeleteAnnouncement}
          isOpen={isDeleteModalOpen}
          closeHandler={closeDeleteModal}
          title={'Announcement'}
          type={EModalType.DELETE}
          content="title"
        />
        <FilterModal
          isOpen={isFilterModalOpen}
          isLoading={isLoading}
          closeHandler={closeFilterModal}
          submitHandler={filterAnnouncement}
        />
      </Box>
    </Box>
  )
}
export default observer(AnnouncementAdmin)
