import React, { useState } from 'react'
import { HStack, FormControl, VStack } from '@chakra-ui/react'
import Button from 'components/Button'
import DatePicker from 'components/DatePicker'
import InputDropdown from 'components/Dropdown/InputDropdown'
import MultipleSelect from 'components/MultipleSelect'
import SwitchControl from 'components/SwitchControl/SwitchControlWithTwoLabel'
import dayjs from 'dayjs'
import { useFormContext, Controller } from 'react-hook-form'
import { IOption } from 'constants/common'
import { ETypeOfFilter } from 'constants/enum'
import { getValidArray } from 'utils/commonUtils'
import { generateProjectOptions, generateUserOptions } from 'utils/user'
import { workingPeriodOptions } from '../../constants'
import { IReportFilterFormProps } from '../../constants'

const ReportFilter = (props: IReportFilterFormProps) => {
  const { projectNameList, userNameList, reportWithUserHandler, reportWithProjectHandler, isAnalysisLoading } = props
  const { control, setValue, handleSubmit, getValues } = useFormContext()
  const formValues = getValues()
  const [typeOfFilter, setTypeOfFilter] = useState(ETypeOfFilter.FILTER_BY_PROJECT)
  const { selectedProject, selectedUser, isFilterByProject } = formValues
  let newUserNameList = userNameList
  let newProjectNameList = projectNameList
  if (typeOfFilter === ETypeOfFilter.FILTER_BY_PROJECT) {
    if (selectedProject) {
      newUserNameList = getValidArray(userNameList).filter((user) => {
        const projectIds = user?.projects
        return projectIds.includes(selectedProject.value)
      })
    }
  }
  if (typeOfFilter === ETypeOfFilter.FILTER_BY_USER) {
    if (selectedUser) {
      const selectedUserId = selectedUser.value
      const userData = getValidArray(userNameList).find((user) => {
        return user.id === selectedUserId
      })
      newProjectNameList = getValidArray(projectNameList).filter((project) => {
        const projectIdsData = userData?.projects
        return projectIdsData.includes(project.id)
      })
    }
  }
  const projectOptions: IOption[] = generateProjectOptions(newProjectNameList)
  const userOptions: IOption[] = generateUserOptions(newUserNameList)
  function pickDate(date: Date): void {
    setValue('date', date)
    localStorage.setItem('isPickPastMonth', String(dayjs(date).month() < dayjs().month()))
  }

  function changeFilterTypeHandler(value: string) {
    setValue('selectedUser', null)
    setValue('selectedProject', null)
    setValue('isFilterByProject', !value)
    if (value) {
      setTypeOfFilter(ETypeOfFilter.FILTER_BY_USER)
    } else {
      setTypeOfFilter(ETypeOfFilter.FILTER_BY_PROJECT)
    }
  }
  function renderFilterOption() {
    if (typeOfFilter === ETypeOfFilter.FILTER_BY_PROJECT) {
      return (
        <React.Fragment>
          <InputDropdown
            name="selectedProject"
            placeHolder="Select Project"
            optionsData={projectOptions}
            isClearable={false}
          />
          <MultipleSelect
            name="selectedUser"
            key="selectedUser"
            placeholder="Select Users"
            options={userOptions}
            selectedEntityName="Users"
          />
        </React.Fragment>
      )
    }
    return (
      <React.Fragment>
        <InputDropdown name="selectedUser" placeHolder="Select User" optionsData={userOptions} isClearable={false} />
        <MultipleSelect
          name="selectedProject"
          key="selectedProject"
          placeholder="Select Projects"
          options={projectOptions}
          selectedEntityName="Projects"
        />
      </React.Fragment>
    )
  }
  return (
    <HStack width="inherit" background="white" margin={{ base: 2, lg: '16px' }} paddingY={'16px'}>
      <VStack width="inherit" paddingLeft={'16px'}>
        <SwitchControl
          name="isFilterByProject"
          leftLabel="Filter By: User"
          rightLabel="Project"
          handleOnChange={() => changeFilterTypeHandler(isFilterByProject)}
        />
        <HStack width="inherit">
          <HStack width="inherit">
            {renderFilterOption()}
            <InputDropdown
              name="period"
              placeHolder="Select Period"
              optionsData={workingPeriodOptions}
              isClearable={false}
              borderColor="#4D5DFB"
            />
            <FormControl>
              <Controller
                name="date"
                control={control}
                render={({ field: { value } }) => (
                  <DatePicker
                    selectedDate={value}
                    onChange={pickDate}
                    style={{
                      border: '1px solid #4D5DFB',
                      borderColor: '#4D5DFB',
                    }}
                  />
                )}
              />
            </FormControl>
          </HStack>
          <HStack paddingRight={'16px'}>
            <Button
              height="44px"
              rightIcon="arrow-right-line-white.svg"
              isLoading={isAnalysisLoading}
              disabled={isAnalysisLoading}
              onClick={handleSubmit(isFilterByProject ? reportWithProjectHandler : reportWithUserHandler)}>
              View Analytics
            </Button>
          </HStack>
        </HStack>
      </VStack>
    </HStack>
  )
}

export default ReportFilter
