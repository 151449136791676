import { Search2Icon } from '@chakra-ui/icons'
import { Flex, HStack, Input, InputGroup, InputLeftElement, useMediaQuery } from '@chakra-ui/react'
import Button from 'components/Button'
import { backgroundGrey200 } from 'theme/globalStyles'
import { maxMobileSize } from 'constants/common'

interface IHeaderSectionProps {
  openCreateModal: () => void
  openFilterModal?: () => void
  changeName?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const HeaderSection = (props: IHeaderSectionProps) => {
  const { openCreateModal, openFilterModal, changeName } = props
  const [isMobile]: boolean[] = useMediaQuery(maxMobileSize)
  return (
    <Flex justifyContent="space-between" flexWrap="wrap">
      <HStack>
        <InputGroup
          border={`1px solid ${backgroundGrey200}`}
          borderRadius="6px"
          background="white"
          width={{ sm: '160px', md: '300px', lg: '400px' }}>
          <InputLeftElement pointerEvents="none">
            <Search2Icon color="gray.400" />
          </InputLeftElement>
          <Input
            type="search"
            placeholder={isMobile ? 'Search' : 'Search Policy by title...'}
            onChange={changeName}
            border="none"
          />
        </InputGroup>
        <Button variant="outline" onClick={openFilterModal} leftIcon="filter.svg">
          {!isMobile && 'Filter'}
        </Button>
      </HStack>
      <HStack>
        <Button variant="solid" leftIcon="add-white.svg" onClick={openCreateModal}>
          {!isMobile && 'Create Policy'}
        </Button>
      </HStack>
    </Flex>
  )
}
export default HeaderSection
