import { IProjectNameResponse } from 'API/project/constants'
import { IUserNameResponse } from 'API/user/constants'
import { capitalize } from 'lodash'
import { IProject } from 'types/project'
import { ITableHeader } from 'types/table'
import { IOption } from 'constants/common'
import { EWorkingHourPeriods } from 'constants/enum'
export interface IOverviewFilterFormData {
  period: IOption<EWorkingHourPeriods | 'month-to-date'>
  date: Date
  selectedProject: IOption<string>
  selectedUser: IOption<string>[]
}

export interface IReportFilterWithUserFormData {
  period: IOption<EWorkingHourPeriods | 'month-to-date'>
  date: Date
  selectedProject: IOption<string>[]
  selectedUser: IOption<string>
  isFilterByProject: boolean
}

export interface IReportFilterWithProjectFormData {
  period: IOption<EWorkingHourPeriods | 'month-to-date'>
  date: Date
  selectedProject: IOption<string>
  selectedUser: IOption<string>[]
  isFilterByProject: boolean
}

export interface IOverviewFilterFormProps {
  projectNameList: IProject[]
  userNameList: IUserNameResponse[]
  isAnalysisLoading: boolean
  viewAnalyticHandler?: (data: IOverviewFilterFormData) => void
}

export interface IReportFilterFormProps {
  projectNameList: IProjectNameResponse[]
  userNameList: IUserNameResponse[]
  isAnalysisLoading: boolean
  reportWithProjectHandler?: (data: IReportFilterWithProjectFormData) => void
  reportWithUserHandler?: (data: IReportFilterWithUserFormData) => void
}

export const workingPeriodOptions: IOption<EWorkingHourPeriods | 'month-to-date'>[] = [
  { label: capitalize(EWorkingHourPeriods.WEEK), value: EWorkingHourPeriods.WEEK },
  { label: capitalize(EWorkingHourPeriods.MONTH), value: EWorkingHourPeriods.MONTH },
]

export function getOverviewHistoryHeaderList() {
  const headers: ITableHeader[] = [
    {
      Header: 'PROJECT NAME',
      accessor: 'projectName',
    },
    {
      Header: 'NUMBER OF WORKING DEV',
      accessor: 'numberOfDev',
    },
    {
      Header: 'TOTAL WORKING TIME (HRS)',
      accessor: 'workingTimeTotal',
    },
    {
      Header: 'TOTAL SALARY (VND)',
      accessor: 'salaryTotal',
    },
  ]

  return headers
}

export function getOverviewHistoryOfDeveloperHeaderList() {
  const headers: ITableHeader[] = [
    {
      Header: 'USER',
      accessor: 'user',
    },
    {
      Header: 'WORKING TIME (HRS)',
      accessor: 'workingTime',
    },
    {
      Header: 'SALARY (VND)',
      accessor: 'salary',
    },
  ]

  return headers
}
