import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  VStack,
} from '@chakra-ui/react'
import { IProjectNameResponse } from 'API/project/constants'
import Button from 'components/Button'
import GroupChips from 'components/GroupChips'
import MultipleSelect from 'components/MultipleSelect'
import { IOption } from 'constants/common'
import { Messages } from 'constants/index'
import { createOptionsOfReactSelectFromDB } from 'utils/dropdownOptionUtils'
interface IExportDataModal {
  isOpen?: boolean
  closeHandler?: () => void
  exportClickHandler?: () => void
  projectNameList: IProjectNameResponse[]
}

const ExportDataModal = (props: IExportDataModal) => {
  const { isOpen, closeHandler, projectNameList, exportClickHandler } = props
  const projectOptions: IOption[] = [...createOptionsOfReactSelectFromDB(projectNameList)]
  return (
    <Modal isOpen={isOpen} onClose={closeHandler} isCentered size="lg">
      <ModalOverlay />
      <ModalContent maxHeight="400px">
        <ModalHeader
          fontSize="lg"
          fontWeight={500}
          lineHeight={7}
          borderBottom="1px solid #E2E8F0"
          position="sticky"
          top="0"
          bg="white"
          zIndex="3">
          Export Data
        </ModalHeader>
        <ModalCloseButton color={'gray'} background="none" />
        <ModalBody paddingY={0}>
          <Text>{Messages.addMoreProjectToExportData}</Text>
          <VStack>
            <MultipleSelect
              name="selectedProjectsToExport"
              options={projectOptions}
              placeholder="Select Projects"
              selectedEntityName="Projects"
            />
            <VStack alignSelf={'flex-start'}>
              <GroupChips fieldName={`selectedProjectsToExport`} />
            </VStack>
          </VStack>
        </ModalBody>
        <ModalFooter position="sticky">
          <Button height="44px" variant="outline" onClick={closeHandler} customStyles={{ marginRight: '12px' }}>
            Cancel
          </Button>
          <Button height="44px" onClick={exportClickHandler} colorScheme="red">
            Export
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ExportDataModal
